/* eslint-disable prettier/prettier */
import { NextRouter } from "next/router";
import {
	DUMMY_VALUE_FORM,
	DUMMY_VALUE_OTP_SECTION,
} from "modules/apnaLearn/utils/constants";
import { ADVANTAGE_SESSION_STORAGE_KEYS } from "./constants";
import { isWebView } from "./helpers";
import { openNewWebView } from "../../../utils/app-bridge";

// eslint-disable-next-line import/prefer-default-export
export const isApnaAdvantagePage = (pathname) => pathname.startsWith("/apna-advantage");

export function convertToTitleCase(text: string): string {
	if (text) {
		return text
			.split("-")
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(" ");
	}
	return "";
}

export const downloadBrochureAndRedirect = (
	router: NextRouter,
	brochureUrl: string,
	advantageCourseLink: string,
	phoneNumber: string,
	nextStepPath: string,
	isMobile?: boolean,
	flowType?: string
) => {
	sessionStorage?.removeItem(ADVANTAGE_SESSION_STORAGE_KEYS.DOWNLOAD_BROCHURE_ENABLED);
	if (advantageCourseLink) {
		const updatedUrl = new URL(window.location.href);

		if (flowType === "Card_brochure_button" || flowType === "Card_apply_button") {
			updatedUrl.pathname = advantageCourseLink;
            updatedUrl.search = "";
		} else {
			// updatedUrl.pathname = `${advantageCourseLink}/${nextStepPath}`;
			updatedUrl.searchParams.delete(DUMMY_VALUE_OTP_SECTION);
			updatedUrl.searchParams.delete(DUMMY_VALUE_FORM);
		}

		/**
		 * Handling redirection using appInterface exposed by the app.
		 */
		if (isWebView(router.query)) {
			updatedUrl.searchParams.set("phone_number", phoneNumber);
			updatedUrl.searchParams.set("is_full_screen", "true");

			// Building the updated URL to open the pages in a new webview
			updatedUrl.searchParams.set("brochureUrl", encodeURIComponent(brochureUrl)); // Encode and add brochureUrl to updatedUrl

			// Opening all the child pages in a new webview, for a full screen view.
			openNewWebView(updatedUrl.toString());
		} else if (isMobile) {
			updatedUrl.searchParams.set("phone_number", phoneNumber);

			window.open(brochureUrl, "_blank");
			setTimeout(() => {
				router.push({
					pathname: updatedUrl.pathname,
					query: updatedUrl.searchParams.toString(),
				});
			}, 100);
		} else {
			window.open(brochureUrl, "_blank");
			setTimeout(() => {
				router.push({
					pathname: advantageCourseLink,
					query: updatedUrl.searchParams.toString(),
				});
			}, 100);
		}
		return;
	}

	window.open(brochureUrl, "_blank");
};

export const APNA_ADVANTAGES_PAGES_REGEX = {
	COLLEGE_X_COURSE: /^\/apna-advantage\/college\/[^/]+\/course\/[^/]+$/,
	COLLEGE: /^\/apna-advantage\/college\/[^/]+$/,
	COURSE: /^\/apna-advantage\/course\/[^/]+$/,
	INDEX: /^\/apna-advantage\/?$/,
};

export const restoreScrollPosition = () => {
	const { scrollY } = window;
	setTimeout(() => {
		window.scrollTo(0, scrollY);
	}, 0);
};
