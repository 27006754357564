/* eslint-disable prettier/prettier */

import { openCustomTab } from "../../../utils/app-bridge";

export interface UserAuthData {
	userPhoneNumber: string;
	userAccessToken: string;
}

export const isMobile = (): boolean => {
	if (typeof window !== "undefined") {
		return window.innerWidth <= 768; // 768px is a common mobile breakpoint
	}
	return false; // Default to desktop if window is undefined (server-sidxe rendering)
};
export const isWebView = (query: { [key: string]: any }): boolean =>
	query.client === "android" ||
	(typeof window !== "undefined" ? (window as any)?.appInterface : false);

export const isFullScreenWebView = (query: { [key: string]: any } | null): boolean => {
	if (!query || !query.is_full_screen) {
		// In order to ensure backward compatibility, the default must be true because the earlier Android version does not include this query. return true
	}
	return query.is_full_screen === "true";
};

export const getStringFromSessionStorage = (key: string): string | null => {
	if (typeof window !== "undefined") {
		const value = sessionStorage.getItem(key);
		return value || null;
	}
	return null; // Return null if window is undefined (server-side rendering)
};
export const getStringFromLocalStorage = (key: string): string | null => {
	if (typeof window !== "undefined") {
		const value = localStorage.getItem(key);
		return value || null;
	}
	return null; // Return null if window is undefined (server-side rendering)
};
export const APP_VERSION_FOR_WEBVIEW_SHARE = 1022;

export const openBrochureUrlForWebView = (query: { [key: string]: any }) => {
	let isWebViewContainer = query?.client === "android";
	const client = query?.client;
	if (Array.isArray(client)) {
		isWebViewContainer = client.includes("android");
	}
	if (isWebViewContainer && query.brochureUrl) {
		// const shareData = {
		// 	text: "Download the brochure",
		// 	url: decodeURIComponent(query.brochureUrl),
		// };
		// const appVersion = getAppVersion();
		// if (appVersion >= APP_VERSION_FOR_WEBVIEW_SHARE) {
		// 	openShare(JSON.stringify(shareData));
		// 	return true;
		// }
		openCustomTab(decodeURIComponent(query.brochureUrl));
		return true;
	}

	return false;
};

export const isApnaAdvantagePage = (pathname) => pathname.startsWith("/apna-advantage");

const getDaySuffix = (day: number) => {
	if (day > 3 && day < 21) return "th"; // Covers 11th to 20th
	switch (day % 10) {
		case 1:
			return "st";
		case 2:
			return "nd";
		case 3:
			return "rd";
		default:
			return "th";
	}
};

export const getApplicationDeadline = (isoString: string, isFullMonth = true) => {
	const date = new Date(isoString);
	const day = date.getDate();
	const month = date.toLocaleString("en-US", { month: isFullMonth ? "long" : "short" });

	return `${day}${getDaySuffix(day)} ${month}`;
};
